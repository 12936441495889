@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url(./fonts/ProximaNova-Regular.otf) format('truetype');
}
body {
  background: #00324A !important;
  font-family: 'ProximaNova', serif;
  margin: 0;
  padding: 0;
}

iframe {
        position: absolute !important;
      
        /* width: 100vw;
        height: 100vh;
        max-width: 100%; */
        /* margin: 0;
        padding: 0;
        border: 0 none; */
        box-sizing: border-box;
    }

