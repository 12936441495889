/**
 css file for the login page
 */
@font-face {
    font-family: 'ProximaNova';
    src: local('ProximaNova'), url(./fonts/ProximaNova-Regular.otf) format('truetype');
}
.login-button {
    border-radius: 0 !important;
    background: #fff !important;
    color: #003C5E !important;
    width: 180px;
    font-family: 'ProximaNova', serif;
}
.login-button:hover{
    background: #003C5E;
    color: #fff;
}
.login-input {
    border-radius: 0px !important;
    margin-top: inherit;
    color: #fff !important;
    background-color: #00324D !important;
}
.login-input:hover {
    border-color: #003C5E !important;
}
.login-forgot-password {
    color: #ffffff !important;
}
.login-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
}
.login-logo .logo-img {
    width: 160px;
    margin: 0px 0px -15px -15px;
}
.forget-grid {
    margin-top: 24px !important;
}
.invalid {
    margin-bottom: 0px;
    color: red;
    font-weight: bold;
}
